<template>
	<Metier name="communication" title="Communication" :main-menu="[{title: 'Technicien communication', missions: ['Participe à l\'élaboration de la politique de communication interne et externe de la Plateforme et gère la communication sensible', 'Rédige les supports de communication interne et externe', 'Gère les documents et supports de communication (présentations, réseau vidéo, points d\'information…)', 'Gère le matériel du service (appareils photos, vidéoprojecteurs, câbles…)'], competences: ['Maîtrise des techniques, du matériel et des logiciels de communication et de mise en page (PAO)', 'Connaissance des activités, des métiers et des installations de la Plateforme', 'Capacité rédactionnelle'], qualites: ['Sens relationnel, diplomatie', 'Curiosité', 'Organisation', 'Esprit d\'initiative', 'Sens de la confidentialité']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
